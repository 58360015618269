/**
 * This module applies highchart modules and custom highcharts plugins used by Seeq
 *
 * @exports configured Highcharts object
 */

import Highcharts from 'highcharts/highcharts.src.js';
import { browserIsFirefox } from '@/utilities/browserId';

require('highcharts/highcharts-more.src.js')(Highcharts);
require('highcharts/modules/boost.src.js')(Highcharts);
require('../other_components/broken-axis.src.js')(Highcharts);
require('highcharts/modules/heatmap.src.js')(Highcharts);

/**
 * Extend the Axis.getLinePath method in order to visualize breaks with two parallel
 * slanted lines. For each break, the slanted lines are inserted into the line path.
 */
Highcharts.wrap(Highcharts.Axis.prototype, 'getLinePath', function(proceed, lineWidth) {
  // _this refers to the axis
  const _this = this;
  const path = proceed.call(_this, lineWidth);
  const start = path[0];
  const y = start[2];

  Highcharts.each(this.breakArray || [], function(brk) {
    if (_this.horiz) {
      const x = _this.toPixels(brk.from);
      path.splice(3, 0,
        ['L', x - 4, y], // stop
        ['M', x - 9, y + 5], ['L', x + 1, y - 5], // lower slanted line
        ['M', x - 1, y + 5], ['L', x + 9, y - 5], // higher slanted line
        ['M', x + 4, y]
      );
    }
  });

  return path;
});

// http://stackoverflow.com/questions/42772038/highcharts-tooltip-background-according-to-line-without-setting-backgroundcolor
Highcharts.wrap(Highcharts.Tooltip.prototype, 'refresh', function(p, point, mouseEvents) {
  p.call(this, point, mouseEvents);

  const label = this.label;

  if (this.options.backgroundColor === 'series' && point && label) {
    label.attr({
      fill: point.series.color
    });
  }
});

/**
 * Fixes CRAB-11536. Highcharts does a lot of splicing to build the tracker path. This caused Chrome to lock up
 * because of unexplained splice performance issues where calls would become 50-100 times slower in the seeq browser
 * environment. Because we don't need to support IE 8 and lower, we can overwrite the problematic method to avoid
 * the splices. Highcharts only manually manipulates the tracker path so that the path is clickable outdated IE.
 *
 * You can test if this is still needed by trending Area A Temperature with the max interpolation set to 5s (via
 * formula, etc.) and a display range of 1 year. If chrome becomes unresponsive for 10-30 seconds whenever the
 * signal is redrawn, then this code is still needed.
 */
Highcharts.wrap(Highcharts.Series.prototype, 'drawTracker', function(proceed) {
  // If we wouldn't hit the while loop anyway, fallback to the unmodified `drawTracker`
  if (this.options.trackByArea || !this.graphPath.length) {
    proceed.call(this);
    return;
  }

  const each = Highcharts.each;
  const svg = this.svg;
  const hasTouch = this.hasTouch;

  /*
   * The remainder if this function is copied from `drawTrackerGraph` in highcharts.src.js. Apart from code style
   * changes to make linter happy, the changes are noted by 'SEEQ CHANGES'
   */
  const series = this;
  const options = series.options;
  const trackByArea = options.trackByArea;
  const trackerPath = [].concat(
    trackByArea ? series.areaPath : series.graphPath
  );
  const chart = series.chart;
  const pointer = chart.pointer;
  const renderer = chart.renderer;
  const snap = chart.options.tooltip.snap;
  const tracker = series.tracker;
  const onMouseOver = function() {
    if (chart.hoverSeries !== series) {
      series.onMouseOver();
    }
  };
  /*
   * Empirical lowest possible opacities for TRACKER_FILL for an
   * element to stay invisible but clickable
   * IE6: 0.002
   * IE7: 0.002
   * IE8: 0.002
   * IE9: 0.00000000001 (unlimited)
   * IE10: 0.0001 (exporting only)
   * FF: 0.00000000001 (unlimited)
   * Chrome: 0.000001
   * Safari: 0.000001
   * Opera: 0.00000000001 (unlimited)
   */
  const TRACKER_FILL = 'rgba(192,192,192,' + (svg ? 0.0001 : 0.002) + ')';

  // SEEQ CHANGES: Omit a while loop that "Extend[s] end points." As an alternative, we use a round linecap. Highcharts
  // doesn't do this because it is incompatible with VML -- the fallback for IE 8 and lower.

  // draw the tracker
  if (tracker) {
    tracker.attr({
      d: trackerPath
    });
  } else if (series.graph) { // create
    series.tracker = renderer.path(trackerPath)
      .attr({
        'stroke-linecap': 'round', // SEEQ CHANGES
        'stroke-linejoin': 'round', // #1225
        visibility: series.visible ? 'visible' : 'hidden',
        stroke: TRACKER_FILL,
        fill: trackByArea ? TRACKER_FILL : 'none',
        'stroke-width': series.graph.strokeWidth() +
          (trackByArea ? 0 : 2 * snap),
        zIndex: 2
      })
      .add(series.group);

    // The tracker is added to the series group, which is clipped, but
    // is covered by the marker group. So the marker group also needs to
    // capture events.
    each([series.tracker, series.markerGroup], function(tracker) {
      tracker.addClass('highcharts-tracker')
        .on('mouseover', onMouseOver)
        .on('mouseout', function(e) {
          pointer.onTrackerMouseOut(e);
        });

      if (options.cursor) {
        tracker.css({
          cursor: options.cursor
        });
      }

      if (hasTouch) {
        tracker.on('touchstart', onMouseOver);
      }
    });
  }
});

/**
 * Allows negative values for logarithmic Y axes. Taken from:
 * https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/yaxis/type-log-negative/
 */
Highcharts.addEvent(Highcharts.Axis, 'afterInit', function() {
  // @ts-ignore logarithmic property should exist
  const logarithmic = this.logarithmic;
  // @ts-ignore custom.allowNegativeLog property should exist
  if (logarithmic && this.options.custom.allowNegativeLog) {
    // Avoid errors on negative numbers on a log axis
    // @ts-ignore positiveValuesOnly property should exist
    this.positiveValuesOnly = false;

    // Override the converter functions
    logarithmic.log2lin = (num) => {
      const isNegative = num < 0;

      let adjustedNum = Math.abs(num);

      if (adjustedNum < 10) {
        adjustedNum += (10 - adjustedNum) / 10;
      }

      const result = Math.log(adjustedNum) / Math.LN10;
      return isNegative ? -result : result;
    };

    logarithmic.lin2log = (num) => {
      const isNegative = num < 0;

      let result = Math.pow(10, Math.abs(num));
      if (result < 10) {
        result = (10 * (result - 1)) / (10 - 1);
      }
      return isNegative ? -result : result;
    };
  }
});

// Ignore Highcharts error #12 (CRAB-21149)
Highcharts.addEvent(Highcharts.Chart, 'displayError', function(event: any) {
  if (event.code === 12) {
    event.preventDefault();
  }
});

// The heatmap color axis fill is set using the url. However, the url used by Highcharts didn't get updated
// when it changed, such as when we switched worksheets. This ensures the url gets updated before we redraw the chart.
// The fix comes from https://github.com/highcharts/highcharts/pull/14874/files,
// for the issue https://github.com/highcharts/highcharts/issues/5244
Highcharts.addEvent(Highcharts.Chart, 'beforeRedraw', function() {
  const chart = this;

  // @ts-ignore renderer.url property should exist
  if (chart.renderer.url !== window.location.href) {
    // Get all elements with a URL clip-path
    const elements = chart.container
      // @ts-ignore renderer.url property should exist
      .querySelectorAll('[clip-path*="' + chart.renderer.url + '"]');

    // Update the clip-path with the new location.href
    elements.forEach(function(element: Element): void {
      const currentValue = element.getAttribute('clip-path');
      if (currentValue) {
        element.setAttribute(
          'clip-path',
          // @ts-ignore renderer.url property should exist
          currentValue.replace(chart.renderer.url, window.location.href)
        );
      }
    });

    // Update renderer URL
    // @ts-ignore renderer.url property should exist
    chart.renderer.url = (
      // May also need to include WebKit here
      (browserIsFirefox) &&
      document.getElementsByTagName('base').length
    ) ?
      window.location.href
        .split('#')[0] // remove the hash
        .replace(/<[^>]*>/g, '') // wing cut HTML
        // escape parantheses and quotes
        .replace(/([\('\)])/g, '\\$1')
        // replace spaces (needed for Safari only)
        .replace(/ /g, '%20') :
      '';
  }
});

export default Highcharts;
